import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '@/firebase/config'
import { collection, onSnapshot, getDocs, doc, getDoc, query, where } from 'firebase/firestore'

const getDocument = (c, arr, isAsync) => {
  const document = ref(null)

  if (!isAsync) {
    const unsub = onSnapshot(collection(db, c), (snapshot) => {
      if (!arr || arr.length === 0) {
        // get all docs
        console.log('get all docs')
        let results = []
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        })
        document.value = results
      } else if (arr.length === 2) {
        // get specific doc
        console.log('get specific doc')
        snapshot.docs.forEach((doc) => {
          if (doc.data()[arr[0]] === arr[1]) {
            document.value = { ...doc.data(), id: doc.id }
          }
        })
      }
    })

    watchEffect((onInvalidate) => {
      onInvalidate(() => unsub())
    })

    return document
  } else {
    return new Promise(async (resolve, reject) => {
      let querySnapshot
      let result = []

      if (!arr || arr.length === 0) {
        // get all docs
        querySnapshot = await getDocs(collection(db, c))
      } else if (arr.length === 2) {
        // get specific doc
        const q = query(collection(db, c), where(arr[0], '==', arr[1]))
        querySnapshot = await getDocs(q)
      }
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        result.push(doc.data())
      })
      resolve(result)
    })
  }
}

// export function
export { getDocument }
