<template>
  <nav>
    <router-link :to="{ name: 'Home' }"
      ><div class="logo">
        <img src="@/assets/img/logo.svg" /></div
    ></router-link>

    <div class="links">
      <router-link v-if="user" :to="{ name: 'Dashboard' }">Dashboard</router-link>
      <router-link v-if="user && inPortal" :to="{ name: 'Profile' }">Profile</router-link>
      <router-link v-if="!user" :to="{ name: 'Login' }">Login</router-link>
      <a class="margin-top-auto logout" v-if="user" @click="handleClick">Logout</a>
    </div>
  </nav>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import globals from '@/main'

export default {
  setup() {
    const inPortal = ref(Boolean)

    if (globals.FRONTENDPATHS.includes(window.location.pathname)) {
      inPortal.value = false
    } else {
      inPortal.value = true
    }

    const store = useStore()
    const router = useRouter()

    store.subscribe((mutation, state) => {
      if (mutation.type === 'setIsNormalLayout' && mutation.payload === true) {
        inPortal.value = false
      } else {
        inPortal.value = true
      }
    })

    const handleClick = () => {
      store.dispatch('logout')
      router.push('/')
    }

    return {
      handleClick,
      user: computed(() => store.state.user),
      authIsReady: computed(() => store.state.authIsReady),
      inPortal,
    }
  },
}
</script>

<style scoped>
.logo {
  width: 6rem !important;
}
.margin-top-auto {
  margin-top: auto;
}

.isMobile nav .links {
  height: 100vh;
}
.logout {
  cursor: pointer;
}

.normal-layout:not(.isMobile) .logout {
  display: none;
}
</style>
