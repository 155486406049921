<template>
  <div class="view-wrapper">
    <router-link class="logo-wrapper" :to="{ name: 'Home' }"
      ><div class="logo">
        <img src="@/assets/img/logo.svg" /></div
    ></router-link>

    <form @submit.prevent="handleReset">
      <h1>Your portal to the Future</h1>
      <h2>Fill in your email address</h2>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" name="email" v-model="email" required />
      </div>

      <button>Reset</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export default {
  title: 'Reset password',
  setup() {
    const email = ref('')
    const store = useStore()
    const router = useRouter()

    const handleReset = async () => {
      const auth = getAuth()
      sendPasswordResetEmail(auth, email.value)
        .then(async () => {
          await store.dispatch('notify', {
            type: 'success', // warning, error, info, success
            msg: `You've got mail. Check your e-mail inbox !`,
          })
          router.push('/login')
        })
        .catch(async (err) => {
          await store.dispatch('notify', {
            type: 'error', // warning, error, info, success
            msg: err.message.split('Firebase: ')[1],
          })
        })
    }

    return { handleReset, email }
  },
}
</script>

<style scoped>
.form-group input {
  background-color: var(--secondary-bg-color);
}

.logo {
  padding: 0 !important;
  margin: 0 auto !important;
}

.logo-wrapper {
  align-self: end;
  padding: 1rem 0 1rem 0;
}

h1 {
  padding-bottom: 2rem;
  text-align: center;
  color: #11bfe5c4;
  font-size: 1rem;
}

h2 {
  padding-bottom: 2rem;
  text-align: center;
  color: white;
  font-size: 1.3rem;
}

.view-wrapper {
  background: rgb(10, 17, 43);
  background: linear-gradient(51deg, rgba(10, 17, 43, 1) 0%, rgba(21, 32, 74, 1) 44%, rgba(8, 90, 113, 0.3146302466299019) 100%);
  background-color: #0a112b;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 0.25fr 0.75fr;
}

form {
  align-self: start;
  justify-items: center;
  display: grid;
}
form input {
  max-width: 100% !important;
}
form button {
  margin: 2rem 0 4rem 0;
}

form a {
  transition: all 0.3s;
  text-decoration: none;
}

form a:hover {
  color: white;
}
</style>
