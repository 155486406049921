<template>
  <button v-if="showNav" @click="toggleMobileMenu" class="toggle-nav-btn">
    <i class="fas fa-bars"></i>
  </button>
  <div v-if="showNav" @click="toggleMobileMenu" class="backdrop" :class="[mobileMenuActive ? 'mobileMenuActive' : '']"></div>
  <div v-if="authIsReady" :class="[isNormalLayout ? 'normal-layout' : 'portal-layout', mobileMenuActive ? 'mobileMenuActive' : '', isMobile ? 'isMobile' : '']">
    <Notification v-for="(notify, index) of notifications" :type="notify.type" :msg="notify.msg" :timestamp="notify.timestamp"></Notification>
    <Navbar v-if="showNav" />
    <main>
      <!-- <div id="info">{{ screenWidth }}px</div> -->
      <router-view />
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Notification from '@/components/Notification'
import { auth } from '@/firebase/config'
import { onBeforeMount } from '@vue/runtime-core'
import { onAuthStateChanged } from 'firebase/auth'
import { useRouter, useRoute } from 'vue-router'
import { computed, ref, watch } from '@vue/reactivity'
import { useStore } from 'vuex'
import globals from '@/main'

export default {
  components: { Navbar, Notification },
  setup() {
    const mobileMenuActive = ref(false)
    const showNav = ref(true)
    const isMobile = ref(false)
    const screenWidth = ref(1)

    function checkScreen() {
      screenWidth.value = window.innerWidth
      if (window.innerWidth <= 767) {
        mobileMenuActive.value = false
        isMobile.value = true
      } else {
        isMobile.value = false
      }
    }
    // init check
    checkScreen()

    const toggleMobileMenu = () => {
      mobileMenuActive.value = !mobileMenuActive.value
    }

    window.addEventListener('resize', checkScreen)

    const notifications = ref([])

    // routes
    const router = useRouter()
    const route = useRoute()

    // store
    const store = useStore()
    const isNormalLayout = computed(() => store.state.isNormalLayout)

    // route change
    store.subscribe((mutation, state) => {
      if (mutation.type === 'setIsNormalLayout') {
        mobileMenuActive.value = false
        if (window.location.pathname === '/login' || window.location.pathname === '/reset-password') {
          showNav.value = false
        } else {
          showNav.value = true
        }
      }
      if (mutation.type === 'setLastNotification') {
        notifications.value.push(state.lastNotification)
      }
    })

    onBeforeMount(() => {
      const unsub = onAuthStateChanged(auth, (user) => {
        store.commit('setUser', user)

        if (!user) {
          // not logged in
          if (globals.BACKENDPATHS.includes(route.path)) {
            router.push('/login')
          }
        } else if (route.path == '/login') {
          // if user is logged in and wants to visit the login page
          router.push('/')
        }

        store.commit('setAuthIsReady', true)
        unsub()
      })
    })

    return {
      authIsReady: computed(() => store.state.authIsReady),
      isNormalLayout,
      mobileMenuActive,
      toggleMobileMenu,
      showNav,
      notifications,
      isMobile,
      screenWidth,
    }
  },
}
</script>

<style scoped>
#info {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 2rem;
}
.toggle-nav-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
</style>
