<template>
  <div class="container" v-if="isLoaded">
    <Header v-if="userInfo && userInfo.fullName" title="Dashboard" :description="' Welcome ' + userInfo.fullName.split(' ')[0] + ','" />
    <Header v-else title="Dashboard" :description="' Welcome ' + user.email + ','" />

    <div v-for="(result, index) of results" :key="index">
      <div :class="[!result.info ? 'has-no-briefing' : '', 'month']">
        <!-- <div @click="(e) => e.target.nextElementSibling.classList.toggle('active')" class="cols-4"> -->
        <div @click="showInfo" class="cols-4">
          <div class="col">
            <h3>{{ result.month }} {{ result.year }}</h3>
            <p>28 {{ result.prevMonthShort }} till 27 {{ result.monthShort }}</p>
            <template v-for="deposit in result.deposits" :key="result.id">
              <p class="deposit">New deposit: €{{ deposit.amount }}</p>
            </template>
          </div>
          <div class="col">
            <h3>Performance</h3>
            <p>{{ result.percentage }}%</p>
          </div>
          <div class="col">
            <h3>Client Capital</h3>
            <p>End: €{{ result.endCapital }}</p>
            <p>Start: €{{ result.startCapital }}</p>
          </div>
          <div class="col">
            <br />
            <h3 v-if="result.info" class="status-show" style="text-align: center; color: #04f6e9">Show ↓</h3>
            <h3 v-if="result.info" class="status-hide" style="text-align: center; color: #04f6e9">Hide ↑</h3>
          </div>
        </div>
        <div v-if="result.info" class="info">
          <div class="border">
            <h3>Briefing for {{ result.month }}</h3>
            <br />
            <p>Dear {{ userInfo.fullName.split(' ')[0] }},<br /><br /></p>
            <p v-html="result.info"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Header from '@/components/Header'
import { computed, ref, watch, onMounted } from 'vue'

import { db } from '@/firebase/config'
import { collection, onSnapshot, getDocs, doc, getDoc, query, orderBy, where } from 'firebase/firestore'

import { useStore } from 'vuex'
import { getDocument } from '@/composables/getDocument'

// sync operations
const isLoaded = ref(false)

const store = useStore()
const user = computed(() => store.state.user)

const showInfo = (e) => {
  e.target.nextElementSibling.classList.toggle('active')
  e.target.classList.toggle('active')
}

const userInfo = ref(null)

// information to display
const results = []

onMounted(async () => {
  const getResults = await getDocument('results', [], true)
  const getUserInfo = (await getDocument('users', ['id', user.value.uid], true))[0]
  userInfo.value = getUserInfo

  const firstDeposit = getUserInfo.deposits[0]
  const sortedResultsByMonth = getResults.sort((a, b) => a.number - b.number)
  const monthShorts = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

  let capital = 0
  for (let i = 0; i < sortedResultsByMonth.length; i++) {
    let month = sortedResultsByMonth[i]

    if (userInfo.value.id === 'XiaD68KukgTzMiUa6qCld6HgV2R2' && month.id === '2022-November-Nov') {
      month.percentage = 10.33
    }

    if (userInfo.value.id === 'XiaD68KukgTzMiUa6qCld6HgV2R2' && month.id === '2022-December-Dec') {
      month.percentage = -63.26
    }

    const obj = {}
    if (firstDeposit.month === month.id || capital > 0) {
      // start counting
      capital += getDepositsForMonth(month.id).sum
      obj.startCapital = capital.toFixed(2)
      capital = month.percentage < 0 ? capital * (1 - (month.percentage * -1) / 100) : capital * (month.percentage / 100 + 1)
      obj.endCapital = capital.toFixed(2)
      obj.percentage = month.percentage.toFixed(2)
      obj.year = month.id.split('-')[0]
      obj.month = month.id.split('-')[1]
      obj.monthShort = month.id.split('-')[2]
      obj.prevMonthShort = month.id.split('-')[2] === 'Jan' ? 'Dec' : monthShorts[monthShorts.indexOf(month.id.split('-')[2]) - 1]
      obj.deposits = getDepositsForMonth(month.id).deposits
      obj.info = month.info
      results.push(obj)
    }
  }
  results.reverse()

  function getDepositsForMonth(id) {
    const depositsThisMonth = getUserInfo.deposits.filter((x) => x.month === id)
    let sum = 0
    for (const deposit of depositsThisMonth) {
      sum += deposit.amount
    }
    return { sum, deposits: depositsThisMonth }
  }

  isLoaded.value = true
})
</script>

<style scoped>
.month {
  overflow: hidden;
  width: 100%;
  border-radius: 1rem;
  border: 2px solid #505568;
  padding: 0rem;
  margin-bottom: 2rem;
}

.cols-4:not(.has-no-briefing) {
  cursor: pointer;
}

.cols-4 *,
.month.has-no-briefing {
  pointer-events: none;
}

h3,
.col {
  user-select: none;
}

.month:hover:not(.has-no-briefing),
.cols-4.active,
.info.active {
  background-color: #282d44;
}

.cols-4.active .status-show {
  display: none !important;
}

.cols-4.active .status-hide {
  display: block;
}

.month .status-hide {
  display: none;
}

.info.active {
  display: block;
}

.info {
  display: none;
}

.month h3 {
  color: white;
  font-size: 1.1rem;
}

.deposit {
  color: #05f2b0bf;
}

.info {
  border-top: 1px solid #505568;
}

.info h3 {
  color: #04f6e9;
}

.info p {
  color: white;
}
.border {
  padding: 2rem;
  max-width: 800px;

  margin: 0 auto;
}

b {
  font-weight: bold !important;
}
</style>
