<template>
  <div class="container">
    <div v-if="isProfile">
      <!-- Profile not found -->
      <h1>Profile Not Found</h1>
    </div>
    <div v-else>
      <!-- 404 Page doesn't exist -->
      <h1>404 Page doesn't exist</h1>
    </div>
    <router-link :to="{ name: 'Home' }">← Go back to homepage</router-link>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  title: 'Not found',
  setup() {
    const isProfile = ref(true)
    const paramsCount = window.location.pathname.split('/').filter((e) => e).length
    if (paramsCount === 1) {
      // set profile not found
      isProfile.value = true
    } else {
      // page doesnt exist
      isProfile.value = false
    }
    return { isProfile }
  },
}
</script>

<style scoped>
.container {
  text-align: center;
  padding-top: 12rem !important;
}
h1 {
  color: white;
  padding-bottom: 1rem;
}

a {
  text-decoration: none;
  color: aqua;
}
</style>
