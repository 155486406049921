<template>
  <div class="notification" :class="[type, timestamp]">
    <div class="wrapper">
      <i v-if="type == 'info'" class="fas fa-info-circle"></i>
      <i v-if="type == 'success'" class="fas fa-check-circle"></i>
      <i v-if="type == 'error' || type == 'warning'" class="fas fa-exclamation-circle"></i>
      <p>{{ msg }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['msg', 'type', 'timestamp'],
  setup(props) {
    setTimeout(() => {
      // if this is the only animation

      $(`.notification.${props.timestamp}`).css('transform', `translateY(0px)`).css('opacity', `1`)

      // continue animating
      setTimeout(() => {
        $(`.notification.${props.timestamp}`).css('transform', `translateY(-60px)`).css('opacity', `0`)
        setTimeout(() => {
          $(`.notification.${props.timestamp}`).remove()
        }, 800)
      }, 6000)
    }, 20)

    return {
      msg: props.msg,
      type: props.type,
      timestamp: props.timestamp.toString(),
    }
  },
}
</script>

<style scoped>
i {
  font-size: 1.5rem;
  margin: 0 auto;
}
p {
  padding-left: 1rem;
}
.notification {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  margin: 2rem auto 0;
  opacity: 0;
  transform: translateY(-60px);
  transition: 0.8s transform ease, 0.8s opacity ease;
}

.notification .wrapper {
  margin: 0 auto;
  max-width: 600px;
  border-radius: 10px;
  padding: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: 0.1fr 0.9fr;
}

.notification.success .wrapper {
  background-color: #3ebe61;
  color: white;
}

.notification.info .wrapper {
  background-color: #006de6;
  color: white;
}

.notification.warning .wrapper {
  background-color: #ef9400;
  color: white;
}

.notification.error .wrapper {
  background-color: #e92e48;
  color: white;
}

.portal-layout .notification {
  padding-left: 256px;
}

.portal-layout.isMobile .notification {
  padding-left: 0;
}
</style>
