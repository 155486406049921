import { createStore } from 'vuex'
import { auth } from '@/firebase/config'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'

const store = createStore({
  state: {
    user: null,
    authIsReady: false, // check if user is logged in or logged out
    isNormalLayout: false,
    lastNotification: null,
  },

  // only synchronous methods
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    },
    setIsNormalLayout(state, payload) {
      state.isNormalLayout = payload
    },
    setLastNotification(state, payload) {
      state.lastNotification = { ...payload, timestamp: Date.now() }
    },
  },

  // actions can be asynchronous methods
  // actions commit mutations to set the state
  // context is a mutation to be passed in
  actions: {
    // async code
    async login(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete login')
      }
    },
    // async code
    async logout(context) {
      await signOut(auth)
      context.commit('setUser', null)
    },
    // async code
    async notify(context, payload) {
      context.commit('setLastNotification', payload)
    },
  },
})

// export store
export default store
