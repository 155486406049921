<template>
  <header>
    <!-- <p style="text-align: right; float: right">Logged in with {{ user.email }}</p> -->
    <h1 class="header-title">{{ title }}</h1>
    <p class="header-description">{{ description }}</p>
  </header>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  props: ['title', 'description'],
  setup() {
    const store = useStore()
    return {
      user: computed(() => store.state.user),
    }
  },
}
</script>

<style scoped>
h1 {
  color: white;
  font-size: 3rem;
}
header {
  padding: 1rem 0 2rem 0;
}
</style>
