import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import Profile from '@/views/Profile.vue'
import ResetPassword from '@/views/ResetPassword.vue'

import NotFound from '@/views/NotFound.vue'

const titlePrefix = `R&S Capital`
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: `${titlePrefix} | Home`,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: `${titlePrefix} | Login`,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: `${titlePrefix} | Reset Pass`,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: `${titlePrefix} | Dashboard`,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: `${titlePrefix} | Profile`,
    },
  },

  {
    path: '/:NotFound(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: `${titlePrefix} | 404 Not Found`,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'active',
})

//import store
import store from '@/store'
import globals from '@/main'
router.afterEach((to, from) => {
  document.title = `${to.meta.title}`
  if (globals.FRONTENDPATHS.includes(to.fullPath)) {
    store.commit('setIsNormalLayout', true)
  } else if (globals.BACKENDPATHS.includes(to.fullPath)) {
    store.commit('setIsNormalLayout', false)
  }
})

export default router
