// kickstarts application

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// global styles
import './assets/colors.css'
import './assets/reset.css'
import './assets/main.css'
import './assets/layout.css'
import './assets/animations.css'

//import store
import store from './store'

//set global vars
const globals = {
  FRONTENDPATHS: ['/', '/login', '/reset-password'],
  BACKENDPATHS: ['/dashboard', '/profile'],
}

let app = createApp(App)
app.config.globalProperties.globalVars = globals
app.use(router).use(store).mount('#app')

export default globals
