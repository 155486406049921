<template>
  <div class="container" v-if="isLoaded">
    <Header title="Profile" description="Fill in your profile" />

    <div class="cols-2">
      <div class="col">
        <form @submit.prevent="saveProfile">
          <div class="form-group">
            <label>Full name</label>
            <input v-model.trim="userInfo.fullName" type="text" required />
          </div>

          <div class="form-group">
            <label>Mobile number</label>
            <input v-model.trim="userInfo.mobileNumber" type="number" />
          </div>

          <div class="form-group">
            <label>E-mail</label>
            <input v-model.trim="userInfo.email" type="email" required />
          </div>

          <div class="form-group">
            <label>New Password</label>
            <input v-model.trim="userInfo.newPassword" type="text" />
          </div>

          <button style="margin: 2rem 0 0 0">Save Profile</button>
        </form>
      </div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script setup>
import Header from '@/components/Header'
import { computed, ref, watch, onMounted } from 'vue'

import { useStore } from 'vuex'
import { getDocument } from '@/composables/getDocument'

import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@/firebase/config'

import { getAuth, updateEmail, updatePassword } from 'firebase/auth'

const auth = getAuth()
const currUser = auth.currentUser

// refs
const isLoaded = ref(false)

// sync operations
const store = useStore()
const user = computed(() => store.state.user)
const userInfo = ref(null)

onMounted(async () => {
  const getUserData = (await getDocument('users', ['id', user.value.uid], true))[0]
  userInfo.value = { ...getUserData, email: currUser.email, newPassword: '' }
  isLoaded.value = true
})

// functions
const saveProfile = async () => {
  const usersRef = doc(db, 'users', userInfo.value.id)

  let passError = false
  if (userInfo.value.newPassword.length > 0) {
    if (userInfo.value.newPassword.length >= 6) {
      // update
      updatePassword(auth.currentUser, userInfo.value.newPassword)
        .then(async () => {
          // Update successful.
        })
        .catch(async (error) => {
          console.log(error)
          await store.dispatch('notify', {
            type: 'info', // warning, error, info, success
            msg: `Your password change needs a recent login. Logout and login to change this !`,
          })
          passError = true
          return
        })
    } else {
      // min six warning
      await store.dispatch('notify', {
        type: 'warning', // warning, error, info, success
        msg: `Password needs to have atleast 6 characters !`,
      })
      return
    }
  }

  let tempTimeout = setTimeout(async () => {
    clearTimeout(tempTimeout)
    if (passError) return
    await updateDoc(usersRef, {
      fullName: userInfo.value.fullName,
      mobileNumber: userInfo.value.mobileNumber,
    })

    if (userInfo.value.email !== currUser.email) {
      updateEmail(auth.currentUser, userInfo.value.email)
        .then(async () => {
          await store.dispatch('notify', {
            type: 'success', // warning, error, info, success
            msg: `Profile has been saved succesfully !`,
          })
        })
        .catch(async (error) => {
          console.log(error)
          await store.dispatch('notify', {
            type: 'info', // warning, error, info, success
            msg: `Your e-mail change needs a recent login. Logout and login to change this !`,
          })
        })
    } else {
      await store.dispatch('notify', {
        type: 'success', // warning, error, info, success
        msg: `Profile has been saved succesfully !`,
      })
    }

    // clear new password field
    userInfo.value.newPassword = ''
  }, 500)
}
</script>

<style>
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-group label {
  color: rgb(255 255 255 / 70%);
  font-size: 0.8rem;
  padding-bottom: 0.3rem;
}

.form-group input {
  background-color: rgb(10 17 43 / 20%);
  border-radius: 0.5rem;
  border: 2px solid #353e60;
  padding: 0.8rem 1.4rem;
  font-size: 1.2rem;
  color: white;
  max-width: 20rem;
  outline: none;
}
.form-group input:focus {
  border: 2px solid #8793bb;
}
</style>
