<template>
  <div class="view-wrapper">
    <router-link class="logo-wrapper" :to="{ name: 'Home' }"
      ><div class="logo">
        <img src="@/assets/img/logo.svg" /></div
    ></router-link>

    <form @submit.prevent="handleSubmit">
      <h2>Login</h2>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" name="email" v-model="email" required />
      </div>

      <div class="form-group">
        <label for="email">Password</label>
        <input type="password" name="password" v-model="password" required />
      </div>

      <button>Login</button>
      <router-link :to="{ name: 'ResetPassword' }">Forgot your password?</router-link>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  title: 'Login',
  setup() {
    const email = ref('')
    const password = ref('')

    const store = useStore()
    const router = useRouter()

    const handleSubmit = async () => {
      try {
        // dispatch runs an action from the store
        await store.dispatch('login', {
          email: email.value,
          password: password.value,
        })
        router.push('/dashboard')
        await store.dispatch('notify', {
          type: 'success', // warning, error, info, success
          msg: `You are succesfully logged in with ${email.value}`,
        })
      } catch (err) {
        await store.dispatch('notify', {
          type: 'error', // warning, error, info, success
          msg: err.message.split('Firebase: ')[1],
        })
      }
    }

    return { handleSubmit, email, password }
  },
}
</script>

<style scoped>
.form-group input {
  background-color: var(--secondary-bg-color);
}

.logo {
  padding: 0 !important;
  margin: 0 auto !important;
}

.logo-wrapper {
  align-self: end;
  padding: 1rem 0 1rem 0;
}

h1 {
  padding-bottom: 2rem;
  text-align: center;
  color: #11bfe5c4;
  font-size: 1rem;
}

h2 {
  padding-bottom: 2rem;
  text-align: center;
  color: white;
  font-size: 1.3rem;
}

.view-wrapper {
  background: rgb(10, 17, 43);
  background: linear-gradient(51deg, rgb(6 12 35) 0%, rgb(12 16 35) 44%, rgb(16 31 54) 100%);
  background-color: #0a112b;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 0.25fr 0.75fr;
}
form {
  align-self: start;
  justify-items: center;
  display: grid;
}

form input {
  max-width: 100% !important;
}
form button {
  margin: 2rem 0 4rem 0;
}

form a {
  transition: all 0.3s;
  text-decoration: none;
}

form a:hover {
  color: white;
}
</style>
