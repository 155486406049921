// default
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

// import services
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfigDev = {
  apiKey: 'AIzaSyDV45AfO5492UFQwvx21ZXPPKTMrU8W4AY',
  authDomain: 'fb9-vuex.firebaseapp.com',
  databaseURL:
    'https://fb9-vuex-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'fb9-vuex',
  storageBucket: 'fb9-vuex.appspot.com',
  messagingSenderId: '114681842325',
  appId: '1:114681842325:web:1085ad731a468752fda99c',
}

const firebaseConfigLive = {
  apiKey: 'AIzaSyBOY3xPBm4qu6g5pSGUi_7Uq4WpBhlN724',
  authDomain: 'rns-live.firebaseapp.com',
  projectId: 'rns-live',
  storageBucket: 'rns-live.appspot.com',
  messagingSenderId: '1003967260378',
  appId: '1:1003967260378:web:ff561b139af26d7ad927e3',
}

// Initialize Firebase
const app =
  process.env.NODE_ENV === 'development'
    ? initializeApp(firebaseConfigDev)
    : initializeApp(firebaseConfigLive)

if (process.env.NODE_ENV === 'production') {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      '6LfFPdgdAAAAAJyJXaBV__yk9kNkn0Jt6D5IteAs',
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  })
}

// Initialize Auth
const auth = getAuth()
const db = getFirestore()

export { auth, db }
